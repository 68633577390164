<template>
	<div class="main">
		<div>
			<Row :gutter="16">
				<Col class-name="mar-b16" :xs="12" :sm="12" :md="12" :lg="12" :xl="6">
				<Card class="center" dis-hover>
					<Avatar size="large" class="sass-num"><i class="iconfont icon-SAAS-kehuguanli"></i></Avatar>
					<div class="card-number">{{ data.auth_num }}</div>
					<div class="card-desc">sass客户数</div>
				</Card>
				</Col>
				<Col class-name="mar-b16" :xs="12" :sm="12" :md="12" :lg="12" :xl="6">
				<Card class="center" dis-hover>
					<Avatar size="large" class="private-num"><i class="iconfont icon-baobiaokongzhitai"></i></Avatar>
					<div class="card-number">{{ data.private_num }}</div>
					<div class="card-desc">独立部署客户数</div>
				</Card>
				</Col>
				<Col class-name="mar-b16" :xs="12" :sm="12" :md="12" :lg="12" :xl="6">
				<Card class="center" dis-hover>
					<Avatar size="large" class="site-num"><i class="iconfont icon-zhandian"></i></Avatar>
					<div class="card-number">{{ data.sass_num }}</div>
					<div class="card-desc">站点数</div>
				</Card>
				</Col>
				<Col class-name="mar-b16" :xs="12" :sm="12" :md="12" :lg="12" :xl="6">
				<Card class="center" dis-hover>
					<Avatar size="large" class="auth-num"><i class="iconfont icon-shouquan"></i></Avatar>
					<div class="card-number">{{ data.site_num }}</div>
					<div class="card-desc">授权数</div>
				</Card>
				</Col>
			</Row>
		</div>
		<div>
			<Row :gutter="16">
				<Col class-name="mar-b16" :xs="24" :sm="24" :md="24" :lg="24" :xl="18">
				<Card dis-hover style="height: 480px;">
					<div slot="title">
						<Avatar size="small" class="auth-num"><i class="iconfont icon-caiwutongji"></i></Avatar>
						近七天订单数据统计
					</div>
					<div id="order-main" style="height: 400px;"></div>
				</Card>
				</Col>
				<Col :xs="24" :sm="24" :md="24" :lg="24" :xl="6">
				<Row :gutter="16">
					<Col class-name="mar-b16" :xs="24" :sm="12" :md="12" :lg="12" :xl="24">
					<Card dis-hover style="height: 232px;">
						<div slot="title">
							<Avatar size="small" class="sass-num"><i class="iconfont icon-wuguan"></i></Avatar>
							AI订单
						</div>
						<div id="myChart2" style="height: 140px;"></div>
					</Card>
					</Col>
					<Col class-name="mar-b16" :xs="24" :sm="12" :md="12" :lg="12" :xl="24">
					<Card dis-hover style="height: 232px;">
						<div slot="title">
							<Avatar size="small" class="private-num"><i class="iconfont icon-duanxin"></i></Avatar>
							短信订单
						</div>
						<div id="myChart3" style="height: 140px;"></div>
					</Card>
					</Col>
				</Row>
				</Col>
			</Row>
		</div>
		<Card dis-hover>
			<div slot="title" class="title">
				<Avatar size="small" class="site-num"><i class="iconfont icon-zhandian"></i></Avatar>
				全部站点
			</div>
			<div class="site-cont">
				<div class="site-item" v-for="(item,index) in siteItem" :key="index">
					<div class="is_sass">{{ type_arr[item.type] }}</div>
					<div class="current_version">{{ item.edition }}</div>
					<div class="site-img"><img src="../../assets/logo.png" alt=""></div>
					<div class="site-text">
						<div>{{ item.name }}</div>
						<div style="width: 100%;word-wrap:break-word;">{{ item.domain_name }}</div>
					</div>
					<div class="action">
						<p>管</p>
						<p>理</p>
					</div>
				</div>
			</div>
			<Page class-name="page-r" :total="SearchForm.total" @on-change="changePage"
				@on-page-size-change="changePageSize" :page-size-opts="[10,15,20]" :page-size="SearchForm.limit"
				:current="SearchForm.page" show-total show-sizer></Page>
		</Card>
	</div>
</template>
<style>
	@import "../../assets/public/css/home.css";
</style>
<script>
	var myChart1, myChart2, myChart3;
	import * as echarts from 'echarts'
	export default {
		name: 'Home',
		data() {
			return {
				SearchForm: {
					name: null,
					page: 1,
					limit: 10,
					total: 0,
				},
				data: {
					auth_num: 0,
					private_num: 0,
					sass_num: 0,
					site_num: 0,
				},
				siteItem: [],
				option: {
					color: ['#00DDFF', '#37A2FF'],
					legend: {
						right: '4%',
						data: ['短信充值订单', 'AI充值订单']
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow',
							label: {
								backgroundColor: '#6a7985'
							}
						},
						formatter: function(params) {
							var result = ''
							for (let i = 0; i < params.length; i++) {
								result += result == '' ? params[i].seriesName + '：' + params[i].value + '元' :
									'<br />' + params[i].seriesName + '：' + params[i].value + '元'
							}
							return result
						}
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '3%',
						containLabel: true
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: []
					},
					yAxis: {
						type: 'value'
					},
					series: [{
							name: '短信充值订单',
							type: 'line',
							smooth: true,
							showSymbol: false,
							lineStyle: {
								width: 0
							},
							areaStyle: {
								opacity: 0.8,
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: 'rgb(0, 221, 255)'
									},
									{
										offset: 1,
										color: 'rgb(77, 119, 255)'
									}
								])
							},
							emphasis: {
								focus: 'series'
							},
							data: [],
						},
						{
							name: 'AI充值订单',
							type: 'line',
							smooth: true,
							showSymbol: false,
							lineStyle: {
								width: 0
							},
							areaStyle: {
								opacity: 0.8,
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: 'rgb(55, 162, 255)'
									},
									{
										offset: 1,
										color: 'rgb(116, 21, 219)'
									}
								])
							},
							emphasis: {
								focus: 'series'
							},
							data: [],
						},
					]
				},
				option2: {
					tooltip: {
						trigger: 'item',
						formatter: '{b0}: {c0}元'
					},
					series: [{
						name: 'AI订单',
						type: 'pie',
						radius: ['40%', '70%'],
						avoidLabelOverlap: false,
						itemStyle: {
							borderRadius: 10,
							borderColor: '#fff',
							borderWidth: 2
						},
						label: {
							show: false,
							position: 'center'
						},
						emphasis: {
							label: {
								show: true,
								fontSize: '14',
								fontWeight: 'bold'
							}
						},
						labelLine: {
							show: false
						},
						data: []
					}]
				},
				option3: {
					tooltip: {
						trigger: 'item',
						formatter: '{b0}: {c0}元'
					},
					legend: {
						left: '1%',
						orient: "vertical"
					},
					series: [{
						name: '短信订单',
						type: 'pie',
						radius: ['40%', '70%'],
						avoidLabelOverlap: false,
						itemStyle: {
							borderRadius: 10,
							borderColor: '#fff',
							borderWidth: 2
						},
						label: {
							show: false,
							position: 'center'
						},
						emphasis: {
							label: {
								show: true,
								fontSize: '14',
								fontWeight: 'bold'
							}
						},
						labelLine: {
							show: false
						},
						data: []
					}]
				},
				type_arr: ['商业版', '旗舰版', '开源版'] //版本
			}
		},
		created() {
			this.init()
		},
		mounted() {
			//图表自适应大小
			window.addEventListener('resize', () => {
				myChart1.resize();
				myChart2.resize();
				myChart3.resize();
			});
		},
		methods: {
			init() {
				console.log(11)
				this.get_index_data()
				this.get_site_list()
			},
			get_index_data() {
				var that = this
				this.requestApi('/adm/get_index_data', ).then(function(res) {
					if (res.status) {
						that.data = res.data
						that.option.xAxis.data = res.option.xAxis
						that.option.series[0].data = res.option.series[0]
						that.option.series[1].data = res.option.series[1]
						that.option2.series[0].data = res.option2.series.data
						that.option3.series[0].data = res.option3.series.data
						var chartDom1 = document.getElementById('order-main');
						myChart1 = echarts.init(chartDom1);
						myChart1.setOption(that.option)
						var chartDom2 = document.getElementById('myChart2');
						myChart2 = echarts.init(chartDom2);
						myChart2.setOption(that.option2)
						var chartDom3 = document.getElementById('myChart3');
						myChart3 = echarts.init(chartDom3);
						myChart3.setOption(that.option3)
					}
				})
			},
			get_site_list() {
				var that = this
				this.SearchForm.type = 0
				this.requestApi('/adm/site_list', {
					SearchForm: this.SearchForm
				}).then(function(res) {
					if (res.code == 1) {
						that.SearchForm.total = res.data.data.total
						that.siteItem = res.data.data.data
					}
				})
			},
			changePage(page) {
				this.SearchForm.page = page
				this.init()
			},
			changePageSize(size) {
				this.SearchForm.page = 1
				this.SearchForm.limit = size
				this.init()
			},
		}
	}
</script>