<template>
	<div class="body">
		<Modal title="版本列表" v-model="show" :width="70">
			<Button type="primary" class="mar-btm-10" @click="editShow()">新增版本</Button>
			<Table :data="data" :columns="columns" :loading="loading">
				<template slot="status" slot-scope="{row}">
					<Tag :color="row.status == 1?'success':'error'">{{ row.status == 1?'正常':'禁用' }}</Tag>
				</template>
				<!-- 				<template slot="version_information" slot-scope="{row}">
					<Tooltip placement="top" :content="row.version_information" transfer>
						{{ row.version_information }}
					</Tooltip>
				</template> -->
				<template slot="notes" slot-scope="{row}">
					<Tooltip placement="top" :content="row.notes" transfer>
						{{ row.notes }}
					</Tooltip>
				</template>
				<template slot="operation" slot-scope="{row}">
					<Button @click="editShow(row.id)" type="primary">编辑</Button>
				</template>
			</Table>
			<Page class="page-right" :page="page" :total="total" :page-size="pageSize" show-sizer
				:page-size-opts="[5,10,15]" show-total @on-change="pageChange" @on-page-size-change="pageSizeChange">
			</Page>
		</Modal>
		<Modal v-model="editModal" :title="editData.id == 0?'修改版本':'新增版本'" width="50">
			<Form :label-width="120" :model="editData" :rules="rules" ref="editForm">
				<FormItem label="版本名称" required>
					<Input v-model="editData.version_name" :disabled="editData.id != 0"
						placeholder="请输入版本名称: 1.0.0、1.0.1"></Input>
				</FormItem>
				<FormItem label="版本号" required>
					<Input v-model="editData.version" :disabled="editData.id != 0"
						placeholder="请输入版本号: 1~2147483647"></Input>
				</FormItem>
				<FormItem label="最低系统版本" required>
					<Select v-model="editData.s_id" style="width: 200px;">
						<Option v-for="(item,index) in system_version_list" :value="item.id" :key="index">
							{{ item.version }}
						</Option>
					</Select>
					<div class="tips">选择系统版本后，只有该系统版本及以上版本，才能使用该插件！</div>
				</FormItem>
				<FormItem label="状态" required>
					<i-switch size="large" v-model="editData.status" :true-value="1" :false-value="0">
						<template slot="open">正常</template>
						<template slot="close">禁用</template>
					</i-switch>
				</FormItem>
				<FormItem label="开源版压缩包" required>
					<Button icon="ios-cloud-upload-outline" @click="upload_zip(1)"
						:loading="uploadLink.open_source_status">上传开源版压缩包</Button>
					<div v-if="editData.open_source_link">开源版.zip</div>
					<Progress :percent="uploadLink.open_source_progress" v-if="uploadLink.open_source_status" />
				</FormItem>
				<FormItem label="加密版压缩包" required>
					<Button icon="ios-cloud-upload-outline" @click="upload_zip(0)"
						:loading="uploadLink.encrypt_status">上传加密版压缩包</Button>
					<div v-if="editData.encrypt_link">加密版.zip</div>
					<Progress :percent="uploadLink.encrypt_progress" v-if="uploadLink.encrypt_status" />
				</FormItem>
				<FormItem label="版本信息" required>
					<Editor ref="editorIntro" class="editor-text" :value="editData.version_information"></Editor>
				</FormItem>
				<FormItem label="备注">
					<Input v-model="editData.notes" type="textarea" placeholder="请输入版本备注信息"></Input>
				</FormItem>
			</Form>
			<template #footer>
				<Button type="primary" @click="submitForm" :loading="editLoading">确认</Button>
			</template>
		</Modal>
	</div>
</template>

<script>
	import {
		Form
	} from 'view-design'
	import Editor from '../../../components/common/editor'
	import COS from 'cos-js-sdk-v5'
	export default {
		components: {
			Editor,
		},
		data() {
			return {
				//表单加载状态
				editLoading: false,
				//系统版本
				system_version_list: [{
					version: null,
					id: 0
				}],
				//上传信息
				uploadLink: {
					open_source_status: false,
					encrypt_status: false,
					open_source_progress: 0,
					encrypt_progress: 0
				},
				//修改或新增弹窗
				editModal: false,
				//编辑数据
				editData: {
					id: 0,
					p_id: 0,
					version_name: null,
					version: null,
					notes: null,
					open_source_link: null,
					encrypt_link: null,
					s_id: 0,
					version_information: null,
					status: 1,
				},
				id: 0,
				show: false,
				page: 1,
				total: 0,
				pageSize: 5,
				loading: false,
				columns: [{
					title: "ID",
					key: "id",
					align: 'center'
				}, {
					title: "版本名称",
					key: "version_name",
					align: 'center'
				}, {
					title: "版本号",
					key: "version",
					align: 'center'
				}, {
					title: "状态",
					slot: "status",
					align: 'center'
				}, {
					title: "创建时间",
					key: "create_date",
					width: 150,
					align: 'center'
				}, {
					title: "最低系统版本",
					key: "s_version",
					align: 'center'

				}, {
					title: "备注",
					slot: "notes",
					align: 'center'
				}, {
					title: "操作",
					slot: "operation",
					align: 'center'
				}],
				data: [{
					id: 1,
					version: 1.0,
					create_date: "2024-10-10 10:10:10",
					notes: "无",
					s_version: 1.0,
					status: 1,
					version_information: "1、优化逻辑"
				}, {
					id: 2,
					version: 1.0,
					create_date: "2024-10-10 10:10:10",
					notes: "无",
					status: 0,
					s_version: 1.0,
					version_information: "1、优化逻辑"
				}],
				rules: {
					version_name: [{
						required: true,
						message: '请输入版本名称',
						trigger: 'blur'
					}],
					version: [{
						required: true,
						message: '请输入版本号',
						trigger: 'blur'
					}],
					s_id: [{
						required: true,
						message: '请选择最低系统版本',
						trigger: 'change'
					}],
					open_source_link: [{
						required: true,
						message: '请上传开源版插件压缩包',
						trigger: 'blur'
					}],
					encrypt_link: [{
						required: true,
						message: '请上传加密版插件压缩包',
						trigger: 'blur'
					}],
					version_information: [{
						required: true,
						message: '请输入版本信息',
						trigger: 'blur'
					}]
				},
			}
		},
		methods: {
			//打开弹窗
			open(id) {
				this.id = id
				this.show = true
				this.init()
			},
			//初始化数据
			init() {
				let _this = this
				this.loading = true
				this.requestApi("/adm/get_plugin_version", {
					id: this.id,
					page: this.page,
					pageSize: this.pageSize
				}).then((res) => {
					_this.loading = false
					if (res.status) {
						_this.data = res.data.data
						_this.total = res.data.total
						_this.system_version_list = res.data.system_version
					} else {
						_this.alertErr(res.msg)
					}
				})
			},
			//页码修改
			pageChange(page) {
				this.page = page
				this.init()
			},
			//分页修改
			pageSizeChange(pageSize) {
				this.pageSize = pageSize
				this.init()
			},
			//显示新增/编辑弹窗
			editShow(id = 0) {
				let _this = this
				if (id == 0) {
					this.editData = {
						id: 0,
						p_id: 0,
						version: null,
						notes: null,
						s_id: 0,
						version_information: null,
						open_source_link: null,
						encrypt_link: null,
						status: 1,
						version_name: null
					}
					this.$refs.editorIntro.setContent(null)
					this.editModal = true
				} else {
					this.requestApi("/adm/get_plugin_version_dtl", {
						id: id
					}).then((res) => {
						if (res.status == 1) {
							_this.$refs.editorIntro.setContent(res.data.version_information)
							_this.editData = res.data
							_this.editModal = true
						} else {
							_this.alertErr(res.msg)
						}
					})
				}
			},
			//上传压缩包
			upload_zip(type) {
				var _this = this
				var input = document.createElement('input')
				input.setAttribute('type', 'file')
				input.setAttribute('accept', '.zip')
				input.onchange = function() {
					let files = this.files[0]
					_this.upload_progress(files, type)
				}
				input.click()
			},
			//调用腾讯云上传压缩包
			upload_progress(file, type) {
				if (type) {
					this.uploadLink.open_source_progress = 0
					this.uploadLink.open_source_status = true
				} else {
					this.uploadLink.encrypt_progress = 0
					this.uploadLink.encrypt_status = true
				}
				let _this = this
				this.putObject(file, type).then(
					(res) => {
						let url = res.pic_url
						let last = url.lastIndexOf('/')
						let name = url.substring(last + 1, url.length)
						if (type) {
							this.uploadLink.open_source_status = false
							this.editData.open_source_link = 'https://' + url
						} else {
							this.uploadLink.encrypt_status = false
							this.editData.encrypt_link = 'https://' + url
						}
					}
				)
			},
			//腾讯云存储操作
			putObject(fileObject, type) {
				let cos = new COS({
					SecretId: 'AKIDNoInIiR5e7Wp2BILKF7woeSIC5udGoIh',
					SecretKey: 'OfqJh0CINORVafi0HVgpuxOpFd0JZp4J'
				})
				let _this = this
				return new Promise((resolve) => {
					cos.putObject({
						Bucket: 'xunliao-1-1257941115',
						Region: 'ap-nanjing',
						Key: 'store_id_13/' + _this.generateFileName(),
						Body: fileObject,
						onProgress: function(progressData) {
							let percent = progressData.percent * 100 | 0;
							if (type) {
								_this.uploadLink.open_source_progress = percent
							} else {
								_this.uploadLink.encrypt_progress = percent
							}
						}
					}, function(err, data) {
						if (err) {
							if (type) {
								_this.uploadLink.open_source_status = false
							} else {
								_this.uploadLink.encrypt_status = false
							}
							return _this.alertErr(err)
						}
						resolve({
							pic_url: data.Location
						})
					})
				})
			},
			//提交版本
			submitForm() {
				let _this = this
				let formData = this.editData
				formData.version_information = this.$refs.editorIntro.getContent()
				this.validateForm().then((validate) => {
					if (validate) {
						formData.p_id = _this.id
						_this.requestApi('/adm/edit_plugin_version', {
							form: formData
						}).then((res) => {
							if (res.status == 1) {
								_this.alertSucc(res.msg)
								_this.editModal = false
								_this.init()
							} else {
								_this.alertErr(res.msg)
							}
						})
					}
				})
			},
			//验证表单
			validateForm() {
				let _this = this;
				return new Promise((resolve) => {
					const keys = Object.keys(this.rules);
					for (const key of keys) {
						let ruleItem = _this.rules[key];
						let dataItem = _this.editData[key];
						if (ruleItem && ruleItem[0].required && !dataItem) {
							_this.alertErr(ruleItem[0].message);
							return resolve(false);
						}
					}
					return resolve(true);
				});
			}
		}
	}
</script>

<style scoped>
	.page-right {
		text-align: right;
		margin-top: 10px;
	}

	.mar-btm-10 {
		margin-bottom: 10px;
	}

	.tips {
		color: red;
		font-size: 12px;
	}
</style>