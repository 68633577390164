<template>
	<div>
		<div class="ser-form ser-form-centered">
			<div class="form-item-content">
				<Input clearable class="margin-right" v-model="serData.name" placeholder="输入操作名称搜索"
					style="width: 200px" />
				<Select v-model="serData.model_id" style="width:200px" class="margin-right" :clearable="true"
					placeholder="请选择设备类型">
					<Option v-for="(item,index) in type_data" :value="item.id" :key="index">{{ item.name }}
					</Option>
				</Select>
				<Button type="primary" @click="init()" class="margin-right">搜索</Button>
				<Button type="primary" class="margin-right" @click="openCorrelation">关联</Button>
				<Button type="primary" icon="md-add" @click="openOperation(0)">新增设备操作</Button>
			</div>
		</div>
		<div class="table-list">
			<Table :columns="columns" :data="data" :loading="loading">
				<template slot-scope="{row}" slot="operation">
					<Button @click="openOperation(1,row)" style="margin-right: 8px;">编辑</Button>
					<Button @click="del(row.id)">删除</Button>
				</template>
				<template slot-scope="{row}" slot="quantity_type">
					<div>{{ row.quantity_type+'个' }}</div>
				</template>
				<template slot-scope="{row}" slot="model_id">
					<Tooltip :content="row.model_id" placement="top" :transfer="true" :events-enabled="true"
						max-width="200">
						<div class="text-ellipsis">{{ row.model_id }}</div>
					</Tooltip>
				</template>
			</Table>
		</div>
		<div class="ser-page">
			<Page class-name="page-r" :total="total" :page-size="serData.pageSize" :page-size-opts="[15,30,50]"
				@on-change="changePage" @on-page-size-change="changePageSize" show-total show-sizer></Page>
		</div>

		<!-- 新增/编辑操作类型 -->
		<Modal :title="edit_data.id ?'编辑设备操作':'新增设备操作'" v-model="model">
			<Form ref="edit_data" :model="edit_data" :rules="ruleCustom" :label-width="80">
				<FormItem label="操作名称">
					<Select v-model="edit_data.op_id" style="width:300px" class="margin-right" :clearable="true"
						placeholder="选择操作名称" @on-change="display_quantity">
						<Option v-for="(item,index) in operation_data" :value="item.op_id" :key="index">{{ item.name }}
						</Option>
					</Select>
				</FormItem>
				<FormItem label="数量" v-if="quantity_model">
					<InputNumber style="width:300px" :min="1" placeholder="选择数量" v-model="edit_data.quantity_type" />
				</FormItem>
				<FormItem label="备注">
					<Input style="width:300px" placeholder="请输入备注" v-model="edit_data.remarks"></Input>
				</FormItem>
			</Form>
			<div slot="footer">
				<Button type="primary" @click="edit_operation">保存</Button>
				<Button @click="model=false">取消</Button>
			</div>
		</Modal>

		<!-- 关联 -->
		<Modal title="关联" v-model="correlation_model" @on-visible-change="correlation_chang">
			<Form ref="edit_data" :model="edit_data" :rules="ruleCustom" :label-width="80">
				<FormItem label="设备类型">
					<Select v-model="edit_data.model_id" style="width:400px" class="margin-right" placeholder="选择设备类型"
						@on-change="get_correlation_operation">
						<Option v-for="(item,index) in type_data" :value="item.id" :key="index">{{ item.name }}
						</Option>
					</Select>
				</FormItem>
				<FormItem label="操作名称">
					<Select v-model="edit_data.id_array" style="width:400px" class="margin-right" placeholder="选择操作名称"
						:disabled="operation_disabled" :multiple="true">
						<Option v-for="(item,index) in operation_data" :value="item.op_id" :key="index">
							{{ item.name+' ('+item.quantity_type+'个)' }}
						</Option>
					</Select>
				</FormItem>
			</Form>
			<div slot="footer">
				<Button type="primary" @click="edit_correlation">保存</Button>
				<Button @click="correlation_model=false">取消</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				total: 0,
				loading: false,
				serData: {
					page: 1,
					pageSize: 15,
					name: null,
					model_id: null,
				},
				model: false,
				data: [],
				columns: [{
					title: 'ID',
					key: 'id'
				}, {
					title: '操作名称',
					key: 'name'
				}, {
					title: '类型',
					slot: 'model_id'
				}, {
					title: '数量',
					slot: 'quantity_type'
				}, {
					title: '备注',
					key: 'remarks'
				}, {
					title: '操作',
					slot: 'operation',
					minWidth: 100
				}],
				type_data: [],
				edit_data: {},
				ruleCustom: {},
				operation_data: [], //操作类型
				quantity_model: false, //是否显示
				correlation_model: false, //是否显示关联框
				operation_disabled: true, //是否禁用操作框
			}
		},
		methods: {
			init() {
				var _this = this
				this.loading = true
				this.requestApi('/adm/get_operation', {
					data: this.serData
				}).then(function(res) {
					_this.loading = false
					if (res.status) {
						_this.data = res.data.data
						_this.type_data = res.data.type_data
						_this.total = res.data.total
					} else {
						console.log(123)
					}
				})
			},
			// 获取操作
			get_operation_type(e = null) {
				var _this = this
				this.requestApi('/adm/get_operation_type', {
					e: e
				}).then(function(res) {
					if (res.status) {
						_this.operation_data = res.data
					}
				})
			},
			//编辑
			openOperation(e, row = null) {
				// this.get_operation_type()
				if (e) {
					var obj = JSON.parse(JSON.stringify(row))
					this.edit_data = obj
					this.display_quantity(this.edit_data['op_id'])
				} else {
					this.edit_data = {}
				}
				// console.log(this.edit_data,this.operation_data)
				this.model = true
			},
			//开打关联框
			openCorrelation() {
				this.get_operation_type(1)
				this.edit_data = {}
				this.operation_disabled = true
				this.correlation_model = true
			},
			//分页
			changePage(page) {
				this.serData.page = page;
				this.init()
			},
			changePageSize(size) {
				this.serData.page = 1;
				this.serData.pageSize = size;
				this.init()
			},
			//新增/编辑操作
			edit_operation() {
				var _this = this
				this.requestApi('/adm/edit_operation_allocation', {
					data: this.edit_data
				}).then(function(res) {
					if (res) {
						_this.alertSucc('成功')
						_this.init()
						_this.model = false
					} else {
						_this.alertErr('失败')
					}
				})
			},
			//是否显示 数量框
			display_quantity(e) {
				console.log(e, this.operation_data)
				if (e && (this.operation_data[e]['default_type'] == 0 || this.operation_data[e]['default_type'] == 3)) {
					this.quantity_model = true
				} else {
					this.quantity_model = false
				}
			},
			//删除操作
			del(id) {
				var _this = this
				this.$Modal.confirm({
					title: '提示',
					content: '确定删除该操作吗？',
					onOk: (res) => {
						this.requestApi('/adm/del_operation_allocation', {
							id: id
						}).then(function(res) {
							if (res.status) {
								_this.alertSucc(res.msg)
								_this.init()
							} else {
								_this.alertErr(res.msg)
							}
						})
					}
				})
			},
			//获取关联操作类型
			get_correlation_operation(e) {
				console.log(e)
				if (e) {
					var _this = this
					this.requestApi('/adm/get_correlation_operation', {
						id: e
					}).then(function(res) {
						if (res) {
							_this.$forceUpdate()
							_this.edit_data.id_array = res.data
							_this.operation_disabled = false
						}
					})
				}
			},
			//关联操作类型
			edit_correlation() {
				var _this = this
				this.requestApi('/adm/edit_correlation', {
					data: this.edit_data
				}).then(function(res) {
					if (res.status) {
						_this.correlation_model = false
						_this.alertSucc(res.msg)
						_this.init()
					} else {
						_this.alertErr(res.msg)
					}
				})
			},
			//取消重新获取操作类型
			correlation_chang(e) {
				if (!e) {
					this.get_operation_type()
				}
			}
		}
	}
</script>

<style scoped>
	.ser-form-centered {
		display: flex;
		align-items: center;
	}

	.margin-right {
		margin-right: 10px;
	}

	.text-ellipsis {
		width: 200px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
</style>