<template>
	<div class="site-body">
		<Tabs v-model="tabValue" @on-click="init">
			<TabPane label="旧版本" name="0"></TabPane>
			<TabPane label="新版本" name="1"></TabPane>
		</Tabs>
		<div class="site-search">
			<Form :model="SearchForm" inline @submit.native.prevent>
				<FormItem>
					<Input type="text" v-model="SearchForm.name" placeholder="请输入站点名称" search enter-button
						@on-search="init" />
				</FormItem>
				<FormItem v-if="tabValue == 0">
					<Button @click="edit(0)">添加</Button>
				</FormItem>
			</Form>
		</div>
		<div class="site-talbe">
			<Table :data="data" :columns="columns" :loading="loading">
				<template slot-scope="{row}" slot="site">
					<div style="padding: 10px 0px 10px 0px">
						<div class="site-item">名称：{{ row.name }}</div>
						<div class="site-item">域名：{{ row.domain_name }}</div>
						<div class="site-item">ip：{{ row.ip }}</div>
					</div>
				</template>
				<template slot-scope="{row}" slot="application_num">
					<span>{{ row.application_num == 0?'无限':row.application_num+'个' }}</span>
				</template>
				<template slot-scope="{row}" slot="status">
					<Tag :color="row.status == 1 ? 'success' : 'red'">{{ row.status == 1 ? '正常' : '封禁' }}</Tag>
				</template>
				<template slot-scope="{row}" slot="sense">
					<Button type="primary" @click="see(row.customer_id)">查看归属</Button>
				</template>
				<template slot-scope="{row}" slot="operate" v-if="tabValue == 0">
					<div>
						<Button style="margin-right: 10px;" @click="edit(row.id)">修改</Button>
						<Button @click="del(row.id)">删除</Button>
					</div>
				</template>
			</Table>
			<Page class-name="page-r" :total="SearchForm.total" @on-change="set_current" @on-page-size-change="set_per"
				:page-size-opts="[10,15,20]" :page-size="SearchForm.limit" :current="SearchForm.page" show-total
				show-sizer></Page>
		</div>
		<Modal v-model="edit_show" :title="edit_id == 0?'新增站点':'修改站点'" :ok-text="edit_id == 0?'新增':'修改'"
			@on-ok="edit_ok" @on-cancel="edit_cancel">
			<Form :model=" edit_data" label-colon :rules="rules" ref="addForm">
				<FormItem label="客户" class="add-item" prop="customer_id">
					<Select v-model="edit_data.customer_id">
						<Option v-for="(item,index) in customer_data" :key="index" :value="item.id" :label="item.name">
						</Option>
					</Select>
				</FormItem>
				<FormItem label="站点名称" prop="name" class="store_number">
					<Input v-model="edit_data.name" type="text" placeholder="请输入站点名称" />
				</FormItem>
				<FormItem label="域名" prop="domain_name" class="add-item">
					<Input v-model="edit_data.domain_name" placeholder="请输入站点域名" type="text" />
				</FormItem>
				<FormItem label="ip地址" prop="ip" class="add-item">
					<Input v-model="edit_data.ip" type="text" placeholder="请输入ip地址" />
				</FormItem>
				<FormItem label="可多开应用数量(个)" prop="application_num" class="add-item">
					<Input type="number" placeholder="站点可以多开的应用数量,0为无限制" v-model="edit_data.application_num"></Input>
				</FormItem>
				<FormItem label="续费价格(元)" prop="update_cost" class="add-item">
					<Input type="number" placeholder="站点更新金额,0为免费" v-model="edit_data.update_cost"></Input>
				</FormItem>
				<FormItem label="设备默认绑定数量" class=" add-item">
					<Input type="number" placeholder="云端账号默认绑定设备数量" v-model="edit_data.device_num"></Input>
				</FormItem>
				<FormItem label="应用多开版本" prop="apply_id" class="add-item">
					<Select v-model="edit_data.apply_id" placeholder="站点每创建一个应用都是该版本">
						<Option v-for="(item,index) in apply_list" :key="index" :value="item.id">
							{{ item.title }}
						</Option>
					</Select>
				</FormItem>
				<FormItem label="服务到期时间" prop="out_time" class="add-item">
					<DatePicker v-model="edit_data.out_time" type="date" placeholder="选择到期时间" style="width: 200px" />
				</FormItem>
				<FormItem label="状态" prop="status" class="add-item">
					<i-switch size="large" v-model="edit_data.status" :true-value="1" :false-value="0">
						<template #open>
							<span>正常</span>
						</template>
						<template #close>
							<span>封禁</span>
						</template>
					</i-switch>
				</FormItem>
				<FormItem label="数据库版本" prop="sql_edition" class="add-item">
					<Input v-model="edit_data.sql_edition" type="text" placeholder="请输入数据库版本" />
				</FormItem>
				<FormItem label="加密版本" prop="code_type" class="add-item">
					<Select v-model="edit_data.code_type">
						<Option :value="0" label="加密版"></Option>
						<Option :value="1" label="开源版"></Option>
					</Select>
				</FormItem><Select v-model="edit_data.customer_id">
					<Option v-for="(item,index) in customer_data" :key="index" :value="item.id" :label="item.name">
					</Option>
				</Select>
				<FormItem label="版本" prop="edition" class="add-item">
					<Input v-model="edit_data.edition" type="text" placeholder="请输入版本" />
				</FormItem>
			</Form>
		</Modal>
		<information ref="information"></information>
	</div>
</template>

<script>
	import information from './custom_information.vue'

	export default {
		components: {
			information
		},
		data() {
			return {
				tabValue: "0",
				loading: true, //开关表格加载状态
				edit_show: false, //显示还是关闭新增/修改对话框
				edit_id: null, //修改站点ID，为0是新增站点，否则为修改站点
				edit_loading: true, //开关对话框中的form表单加载状态
				renew_show: false, //显示还是关闭续费对话框
				renew_id: 0, //续费站点ID
				type_arr: ['商业版', '旗舰版', '开源版'], //版本
				//搜索参数
				SearchForm: {
					name: null,
					page: 1,
					limit: 10,
					total: 0,
				},
				columns: [{
						title: 'ID',
						key: 'id',
						minWidth: 100,
						align: 'center'
					},
					{
						title: '站点',
						slot: 'site',
						align: 'center',
						minWidth: 200
					},
					{
						title: '多开应用数量',
						slot: 'application_num',
						minWidth: 150,
						align: 'center'
					},
					{
						title: '到期时间',
						key: 'out_time',
						minWidth: 150,
						align: 'center'
					},
					{
						title: '当前版本',
						key: 'edition',
						align: 'center',
						minWidth: 150
					},
					{
						title: '状态',
						slot: 'status',
						minWidth: 100,
						align: 'center',
					},
					{
						title: '归属人',
						slot: 'sense',
						minWidth: 200,
						align: 'center'
					},
					{
						title: '操作',
						slot: 'operate',
						minWidth: 250,
						align: 'center',
						fixed: 'right'
					}
				],
				data: [],
				rules: {
					name: [{
						required: true,
						message: '请输入站点名称',
						trigger: 'blur'
					}],
					domain_name: [{
						required: true,
						message: '请输入站点域名',
						trigger: 'blur'
					}],
					apply_id: [{
						required: true,
						message: '请选择应用开通版本',
						trigger: 'change',
						type: 'number'
					}],
					customer_id: [{
						required: true,
						message: '请选择所属客户',
						trigger: 'change',
						type: 'number'
					}],
					ip: [{
						required: true,
						message: '请输入ip地址',
						trigger: 'blur'
					}],
					sql_edition: [{
						required: true,
						message: '请输入数据库版本',
						trigger: 'blur'
					}],
					edition: [{
						required: true,
						message: '请输入PHP版本',
						trigger: 'blur'
					}],
					out_time: [{
						required: true,
						message: '请输入到期时间',
						trigger: 'change',
						type: 'date'
					}],
				},
				//站点修改数据
				edit_data: {
					name: null,
					domain_name: null,
					ip: null,
					type: 1,
					sql_edition: null,
					edition: null,
					customer_id: 0,
					application_num: 0,
					status: 1,
					update_cost: 0,
					apply_id: 0,
					device_num: 0,
					out_time: null,
					code_type: 0
				},
				//应用版本数据
				apply_list: [],
				//可选客户
				customer_data: []
			}
		},
		created() {
			//表格初始化
			this.init()
		},
		methods: {
			//数据查询
			init() {
				var _this = this
				this.SearchForm.type = this.tabValue
				//开启表格加载状态
				this.loading = true
				//发送数据查询请求
				this.requestApi('/adm/site_list', {
					SearchForm: this.SearchForm
				}).then((res) => {
					_this.loading = false //关闭表格加载状态
					if (res.code == 1) {
						_this.data = res.data.data.data //加载数据
						_this.customer_data = res.data.customer_data //可选客户数据
						_this.SearchForm.total = res.data.data.total //获取总条数
						_this.apply_list = res.data.apply_list //可选应用版本
					} else {
						//错误提示语
						_this.$Message.error(res.msg)
					}
				})
			},
			//设置当前页
			set_current(page) {
				//赋值最新当前页
				this.SearchForm.page = page
				//执行数据查询
				this.init()
			},
			//设置每页多少条数据
			set_per(limit) {
				//赋值最新条数
				this.SearchForm.limit = limit
				//执行数据查询
				this.init()
			},
			edit(id) {
				//修改ID,为0则是新增，否则为修改
				this.edit_id = id
				//判断是否是修改
				if (id != 0) {
					//开启form表单加载状态
					this.edit_show = true
					this.get_edit(id)
				}
				//开启对话框
				this.edit_show = true
			},
			//通过站点ID获取相关数据
			get_edit(id) {
				var _this = this
				this.requestApi('/adm/site_edit', {
					id
				}).then(
					(res) => {
						//将查询到的数据赋值
						_this.edit_data = res.data
					}
				)
			},
			//确认提交
			edit_ok() {
				var _this = this
				//获取表单数据
				var edit_data = this.edit_data
				//获取修改ID
				var edit_id = this.edit_id
				this.$refs['addForm'].validate(valid => {
					if (valid) {
						//发送请求
						this.requestApi('/adm/site_edit_save', {
							edit_data,
							edit_id
						}).then(
							(res) => {
								//提示语
								_this.$Message.success(res.msg)
								//初始化
								_this.init()
								_this.$refs['addForm'].resetFields()
								_this.edit_loading = false
								_this.edit_cancel()
							}
						)
					} else {
						setTimeout(() => {
							_this.edit_loading = false // 改变加载状态，且让弹窗无法自动关闭
							_this.$nextTick(() => {
								_this.$Message.error('请完善信息')
								_this.edit_loading = true
							})
						}, 0)
						return
					}

				})
			},
			//取消对话框
			edit_cancel() {
				this.edit_show = false
				this.edit_data = {
					name: null,
					domain_name: null,
					ip: null,
					type: 1,
					sql_edition: null,
					edition: null,
					customer_id: 0,
					application_num: 0,
					status: 1,
					update_cost: 0,
					apply_id: 0,
					device_num: 0,
					out_time: null,
					code_type: 0
				}
				this.$refs['addForm'].resetFields()
			},
			//删除站点
			del(id) {
				var _this = this
				this.$Modal.confirm({
					title: '删除站点',
					content: '确定删除该站点吗？',
					onOk: () => {
						this.requestApi('/adm/site_del', {
							id
						}).then(
							(res) => {
								//提示语
								_this.$Message.success(res.msg)
								//重载表格数据
								_this.init()
							}
						)
					}
				})

			},
			//打开客户信息抽屉
			see(id) {
				this.$refs['information'].see_show = true
				this.$refs['information'].id = id
				this.$refs['information'].is_modify = false
				this.$refs['information'].init()
			}
		}
	}
</script>

<style scoped>
	.site-body {
		background-color: #fff;
		padding: 10px;
	}

	.site-search {
		background-color: rgb(248, 248, 249);
		padding: 5px;
		margin-bottom: 10px;
	}

	.ivu-form-item {
		margin-bottom: 0px !important;
	}

	.page-r {
		margin-top: 10px;
		text-align: right;
	}

	.site-item {
		line-height: 20px;
		color: rgb(96, 98, 102);
	}

	.add-item {
		margin-bottom: 15px !important;
	}
</style>