<template>
	<div id="app">
		<div id="menu" v-if="showHead" :class="{'small':menuMode=='small'}">
			<left-menu ref="leftMenu"></left-menu>
		</div>
		<div id="nav" v-if="showHead" :class="{'nav-small':menuMode=='small'}">
			<i class="iconfont icon-indent-remove-full" @click="changeMenu" :title="menuMode=='default'?'收缩侧边栏':'展开侧边栏'"
				style="margin-right: 22px;"></i>
			<i class="iconfont icon-refresh-line" @click="clickReload" title="刷新"></i>
			<Dropdown transfer trigger="click" style="float: right;margin-left: 20px" @on-click="changeDown">
				<a href="javascript:void(0)">
					admin
					<Icon type="ios-arrow-down"></Icon>
				</a>
				<DropdownMenu slot="list">
					<DropdownItem name="edit">修改</DropdownItem>
					<DropdownItem name="out_login">退出登录</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		</div>
		<div :class="{'main-view':showHead,'main-small':menuMode=='small'}">
			<keep-alive>
				<router-view ref="reload" v-if="$route.meta.keepAlive"></router-view>
			</keep-alive>
			<router-view ref="reload" v-if="!$route.meta.keepAlive"></router-view>
		</div>
	</div>
</template>
<script>
	import leftMenu from "../components/public/leftMenu";

	export default {
		name: "APP",
		components: {
			leftMenu
		},
		data() {
			return {
				showHead: true,
				menuMode: 'default'
			}
		},
		watch: {
			'$route.path': function(path) {
				this.showHead = path == '/login' ? false : true;
			},
		},
		created() {
			let a = Date.now()
			console.log(a)
			const time = this.timestampToBigEndianArray(1734451200)
			const dataSegment = [...time]
			console.log(dataSegment)
			const parameterSettingInstructionResult = this.generateParameterSettingInstruction(dataSegment);
			console.log("参数设置指令:", parameterSettingInstructionResult.map(byte => byte.toString(16).padStart(2,
				'0')).join(
				''));
			// 测试示例
			let cards = [2, 1, 88, 93, 2, 93, 88, 5, 5];
			this.analysisData(time)
		},
		methods: {
			analysisData(time) {
				let hexDataArray = [
					"FE", "EF", "A2", "80", "00", "00", "67", "4D", "35", "EF", "00", "1E", "00", "00", "00", "00",
					"00", "00", "00", "00", "00", "00", "00", "1C", "00", "00", "00", "1F", "00", "00", "00", "00",
					"00", "00", "00", "00", "00", "00", "00", "17", "4F", "12", "00", "17", "51", "54", "00", "4F",
					"00", "40", "00", "19", "00", "40", "00", "12", "00", "40", "00", "01", "00", "40", "00", "00",
					"4B", "00", "00", "06", "4C", "52", "00", "51", "00", "40", "00", "26", "00", "40", "00", "00",
					"00", "40", "00", "00", "00", "40", "00", "11", "00", "00", "00", "00", "00", "00", "07", "4D",
					"13", "00", "03", "00", "40", "00", "00", "00", "40", "00", "00", "00", "40", "00", "05", "00",
					"40", "00", "00", "00", "00", "00", "00", "00", "00", "00", "00", "00", "02", "00", "00", "00",
					"0B", "ED", "96"
				];

				// 			let hexDataArray = [
				//     254, 239, 162, 128, 0, 0, 103, 77, 39, 239, 0, 30, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 28, 0, 0, 0, 31, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 23, 79, 18, 0, 23, 81, 84, 0, 79, 0, 64, 0, 25, 0, 64, 0, 18, 0, 64, 0, 1, 0, 64, 0, 0, 75, 0, 0, 6, 76, 82, 0, 81, 0, 64, 0, 38, 0, 64, 0, 0, 0, 64, 0, 0, 0, 64, 0, 17, 0, 0, 0, 0, 0, 0, 7, 77, 19, 0, 3, 0, 64, 0, 0, 0, 64, 0, 0, 0, 64, 0, 5, 0, 64, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 11, 237, 150
				// ]
				let date = [hexDataArray[6], hexDataArray[7], hexDataArray[8], hexDataArray[9]]
				// let date = time;
				let a = this.bigEndianArrayToTimestamp(date)
			},
			bigEndianArrayToTimestamp(bigEndianArray) {
				// 创建一个新的ArrayBuffer，并使用提供的大端数组填充它
				let buffer = new ArrayBuffer(4);
				let view = new DataView(buffer);

				for (let i = 0; i < 4; i++) {
					view.setUint8(i, bigEndianArray[i]);
				}

				// 从DataView中读取32位无符号整数（以大端模式）并返回对应的时间戳
				return view.getUint32(0, false);
			},
			timestampToBigEndianArray(timestamp) {
				// 将时间戳转换为32位无符号整数数组（大端格式）
				let buffer = new ArrayBuffer(4);
				let view = new DataView(buffer);
				view.setUint32(0, timestamp, false); // false表示大端模式

				// 将数组缓冲区中的数据读取到普通数组
				let resultArray = [];
				for (let i = 0; i < 4; i++) {
					resultArray.push(view.getUint8(i));
				}

				return resultArray;
			},
			generateParameterSettingInstruction(dataSegment) {
				// 计算数据长度
				const dataLength = dataSegment.length
				// 构造报文头部
				const frameHeader = [0xFE, 0xEF];
				// 报文类型
				const msgType = 0x21;
				// 构造用于计算CRC的数组（先只包含报文类型和数据长度）
				const mt = [msgType, dataLength, ...dataSegment];
				// 计算初始CRC
				let crc = this.calcCRC16XMODEM(mt);

				// 将计算出的CRC转换为两个字节格式（高位在前）
				const calculatedCRC = [(crc >> 8) & 0xff, crc & 0xff];
				// 构造完整的参数设置指令
				const parameterSettingInstruction = frameHeader.concat([msgType, dataLength]).concat(dataSegment)
					.concat(
						calculatedCRC);

				return parameterSettingInstruction;
			},
			// 计算 CRC16 校验值
			calcCRC16XMODEM(data) {
				let crc = 0x0000;
				const polynomial = 0x1021;

				for (let i = 0; i < data.length; i++) {
					crc ^= (data[i] << 8);

					for (let j = 0; j < 8; j++) {
						if (crc & 0x8000) {
							crc = (crc << 1) ^ polynomial;
						} else {
							crc <<= 1;
						}
					}
				}

				return crc;
			},
			changeDown(name) {
				var _this = this;
				if (name == 'out_login') {
					_this.$Modal.confirm({
						title: '提示',
						content: '确认退出登录吗？',
						onOk: () => {
							_this.requestApi('/adm/login_out').then(function(res) {
								if (res.status) {
									_this.showHead = false;
									localStorage.removeItem('store_token');
									setTimeout(function() {
										_this.toUrl('/login');
									}, 500)
									_this.alertSucc(res.msg)
								} else {
									_this.alertErr(res.msg);
								}
							})
						},
					})
				}
			},
			//收缩、展开菜单栏
			changeMenu() {
				this.menuMode = this.menuMode == 'default' ? 'small' : 'default';
			},
			//点击刷新
			clickReload() {
				if (this.$refs.reload.clickReload) this.$refs.reload.clickReload();
				else if (this.$refs.reload.init) this.$refs.reload.init();
			}
		}
	}
</script>
<style>
	@import "./assets/public/css/index.css";
	@import "./assets/public/css/common.css";
	@import "//at.alicdn.com/t/font_2601133_l5q3e1h98nf.css";
	@import "./assets/iconfont/iconfont.css";
</style>